:root {
  --background-color: #fff;
  --text-color: #000;
  --accent: royalblue;
  --gradient: linear-gradient(100deg, red, darkred, black, darkred, red);
}

html {
  font-size: 20px;
  font-weight: 300;
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.5rem;
}

h1 h2 h3 h4 {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 800px) {
  html {
    font-size: 13px;
  }
}
